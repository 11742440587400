<template>
    <div class='lottery_item  all_white' @click="handleEvent('goDetail')">
        <div class="draw_status" v-if="!isWinPage && drawStatus().length > 0 && !isTermly">{{drawStatus()}}</div>
        <!-- 多期 -->
        <div class="lottery_item_c" :class="{'lottery_item_c_free': isFree()}" v-if="isTermly">
            <div class="item_left">
                <img :src="lotteryKind.icon" alt="">
                <div class="item_left_info">
                    <div v-if="is306">
                       <p v-if="progressStatus == '进行中'">剩<span class="red">{{items.nodealcount}}期</span>/共{{items.totalIssueCount}}期</p>
                       <p class="black" v-else>{{progressStatus}}</p>
                    </div>
                    <p v-else :class="progressStatus == '进行中' ? 'green' : 'black'">{{progressStatus}}</p>
                    <p>{{showDate(items.createTime)}}</p>
                </div>
            </div>
            <div class="item_right">
                <p>{{kindTtext}}</p>
                <p>{{is306 ? '总金额' : isWinPage ? '中奖' : '投注'}}<span class="red">{{items.money/100}}</span>元</p>
            </div>
        </div>
        <!-- 普通投注 -->
        <div class="lottery_item_c" :class="{'lottery_item_c_free': isFree()}" v-else>
             <div class="item_left">
                <img :src="lotteryKind.icon" alt="">
                <div class="item_left_info">
                    <p :class="isWinPage ? 'red' : textColor">{{isWinPage ? '已中奖' : priceType}}</p>
                    <p>第{{items.issueName}}期</p>
                </div>
            </div>
            <div class="item_right">
                <p>{{kindTtext}}</p>
                <p>{{isWinPage ? '中奖' : '投注'}}<span class="red">{{items.money/100}}</span>元</p>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { gameIDMap } from '../common-data'
import { formatTime } from '../utils/util'

export default {
    name: '',
    props: {
        items: {
            type: Object,
            default: () => {
                return {
                    drawTime: "2021-10-08 16:12:28",
                    gameId: 11,
                    gameName: "双色球",
                    isRandom: 1,
                    issueName: "2020076",
                    money: 20000,
                    orderId: "2358062000",
                    planType: 2,
                    playTimes: 1,
                    source: 'MESSAGE'
                }
            }
        },
        isTermly: {
            type: Boolean,
            default: false
        },
        isWinPage: {
            type: Boolean,
            default: false
        },
        gameId: {
            type: Number,
            default: 11
        }
    },
    setup(props, context) {
        const state= reactive({
            items: props.items
        })
        let items = state.items
        const lotteryKind = computed(() => {
            return gameIDMap.get(props.gameId)
        })
        const kindTtext = computed(() => {
            const type = items.planType, lotteryType = items.isRandom
            const lotteryTypeText = lotteryType == 0 ? '自选' : '机选'
            const typeText = 
                type == 0 ? ` 多期投注套餐${lotteryTypeText}`
                : (type == 1 ? `自动多期${lotteryTypeText}` : `普通投注`)
            
            // return `${typeText}${lotteryTypeText}${items.amount || items.playTimes || 1}倍`
            return `${typeText}`
        }) 
        const termlyKindText = computed(() => {
            const playType = items.planType
            // isRandom = items.isRandom
            // return `${playType == 0 ? ' 多期投注套餐' : '自动多期投注'}${isRandom == 0 ? '自选' : '机选'}`
            return `${playType == 0 ? ' 多期投注套餐' : '自动多期投注'}`
        })
        const progressStatus = computed(() => {
            const status = items.status
            return status == 0 ? '进行中'
                    : status == 1 ? '订单已完成' : '已撤单'
        })
        const is306 = computed(() => {
            return items.planType == 0
        })
        const priceType = computed(() => {
            const issueDealState = items.issueDealState // 开奖状态
            const itemStatus = items.status
            const isWin = items.isWin
            return itemStatus == -1 ? '彩金已退还' 
                  : issueDealState == 1 ? '未开奖'
                  : issueDealState == 2 ? '已开奖'
                  : isWin == 1 ? '已中奖' : '未中奖'
        })
        function drawStatus(status = items.status) {
            return status == 0 ? '委托成功'
                 : status == 1 ? '出票中'
                 : status == 2 ? '出票成功'
                 : status == -1 ? '出票失败': ''
        }
         const textColor = computed(() => {
            const issueDealState = items.issueDealState // 开奖状态
            const itemStatus = items.status
            const isWin = items.isWin
            return isWin ? 'red' 
                  : itemStatus == -1 ? 'black' 
                  : issueDealState == 1 ? 'green' : 'black'
                //   : issueDealState == 2 ? 'black'
        })
        function lotterInfo() {
            const type = props.planType, lotteryType = props.isRandom
            const typeText = type == 0 ? 306
                : type == 1 ? '自动' : '普通'
            const lotteryTypeText = lotteryType == 0 ? '自选' : '机选'
            const times = items.amount || items.playTimes
            console.log(times)
            return `${typeText}${lotteryTypeText}${times}倍`
        }
        function handleEvent(eventName, value) {
            context.emit(eventName, value)
        }
        function showDate(time) {
            return formatTime(time, 'YYYY.MM.DD HH:mm')
        }
        function isFree() {
            console.log(props.items.source)
            return props.items.source == 'ACTIVITY'
        }
        return {
            ...toRefs(state),
            drawStatus,
            lotterInfo,
            lotteryKind,
            handleEvent,
            kindTtext,
            termlyKindText,
            priceType,
            textColor,
            showDate,
            progressStatus,
            is306,
            isFree
        }
    },
}
</script>
<style lang='scss' scoped>
.green {
    color: #15a962;
}
.black {
    color: #000 !important;
}
.lottery_item {
    background: $bg-gray;
    border-radius: $box-radius-small;
    padding: 10px 12px;
    position: relative;
    overflow: hidden;
    &_c {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
        padding: 8px 0;
    }
    &_c_free {
        background: url('../assets/imgs/free_of_cost_icon.png') 70% center no-repeat;
        background-size: contain;
    }
    .draw_status {
        position: absolute;
        right: -1px;
        top: -1px;
        font-size: $font-less;
        color: #fff;
        background: $theme-light;
        padding: 2px 5px;
        border-radius: 0 0 0 8px;
    }
    .item_left {
        display: flex;
        align-items: center;
        img {
            width: 48px;
            padding-right: 5px;
        }
        &_info {
            p {
                &:first-child {
                    // font-weight: 700;
                    font-size: $font-big;
                }
                &:last-child{
                    // font-size: $font-small;
                    color: $gray-middle;
                    padding-top: 5px;
                }
            }
        }
    }
    .item_right {
        background: url('../assets/imgs/arrow_right_gray.png') right center no-repeat;
        background-size: 10px;
        padding-right: 20px;
        text-align: right;
        // font-size: $font-small;
        p {
            &:last-child{
                color: $gray-middle;
                padding-top: 2px;
                span {
                    font-size: $font-big;
                }
                :deep {
                    .red {
                        margin-left: 0;
                    }
                }
            }
            // span {
            //     font-weight: 700;
            // }
        }
    }
}
</style>