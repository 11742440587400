<template>
    <div class='recard'>
        <ul class="recard_heads">
            <li 
                class="recard_heads_item" 
                v-for="(item, index) in titles" 
                @click="showOptions(index)"
                :key="index">
                <p>{{item}}</p>
            </li>
        </ul>
        <ul class="recard_tabs">
            <li 
                :class="{'recard_tabs_active': index == tabIndex}" 
                class="recard_tabs_item" 
                v-for="(item, index) in tabs" 
                @click="changeTab(index)"
                :key="index">
                <p>{{item}}</p>
            </li>
        </ul>
        <van-list 
                class="recard_list"
                ref='boxRef'
                v-if="hasData"
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="loadData"
                >
                <keep-alive>
                <van-cell v-for="(item, index) in list" :key="index" >
                     <LotteryItem 
                        :items="item"
                        :class="{'dark_gray': index == chooseIndex}" 
                        :gameId="item.gameId"
                        :isTermly="isTermly"
                        @click="goDtail(item, index)"   
                        :isWinPage="isWinPage"/>
                </van-cell>
                </keep-alive>
        </van-list>
        <NoData :tip="tip" v-else/>
        <van-popup v-model:show="show" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                :title="title"
                :options="showOption"
                @close="show = false"
                @finish="finish($event)"
                />
        </van-popup>
    </div>
</template>
<script>
import { computed, onActivated, reactive, toRefs, ref } from 'vue'
import { Cascader, Popup} from 'vant'
import LotteryItem from '../../components/LotteryItem.vue'
import { changeLotteryRecardStatus, getTopValue, getUserInfo, goTop, jumpTo, stardAndEndTime } from '../../utils/util'
import { lotteryRecard, termlyLotteryRecard, winRecard } from '../../utils/api'
import { lotterys } from '../../common-data'
import { List } from 'vant'
import 'vant/lib/datetime-picker/style'
import 'vant/es/popup/style'
import 'vant/es/cascader/style'
import 'vant/lib/list/style'
export default {
    name: '',
    components: { 'van-cascader': Cascader, 'van-popup': Popup, LotteryItem, 'van-list': List},
    setup() {
         const state= reactive({
            tabs: ['普通投注', '中奖列表', '多期投注'],
            // titles: ['双色球', '最近三天'],
            tabIndex: 0,
            options: [
                [
                    {value: 11, text: '双色球'},
                    {value: 51, text: '福彩3D'},
                    {value: 24, text: '七乐彩'},
                    {value: 52, text: '东方6+1'},
                    {value: 23, text: '天天彩选4'},
                    {value: 61, text: '15选5'},
                ],
                 [
                    {value: [7, 'd'], text: '七天'},
                    {value: [1, 'M'], text: '一个月'},
                    {value: [3, 'M'], text: '三个月'},
                ]
            ],
            isWinPage: false,
            showOption: [],
            show: false,
            chooseOptions: [{value: 11, text: '双色球'}, {value: [7, 'd'], text: '七天'},],
            showOptionIndex: 0,
            card: '',
            page: 1,
            pageSize: 10,
            list: [],
            loading: false,
            finished: false,
            scrollTopValue: 0,
            chooseIndex: -1,
        })
        const boxRef = ref()
        const hasData = computed(() => {
            return state.list.length > 0
        })
        const titles = computed(() => {
            return [state.chooseOptions[0].text, state.chooseOptions[1].text]
        })
        const tip = computed(() => {
            return `${state.chooseOptions[0].text}最近${state.chooseOptions[1].text}暂无数据`
        })
        const showOption = computed(() => {
            return state.options[state.showOptionIndex]
        })
        const title = computed(() => {
            return state.showOptionIndex == 0 ? '选择彩种' : '选择时间'
        })
        const isTermly = computed(() => {
            return state.tabIndex == 2
        })
        async function initData() {
            const _arr = []
            lotterys.forEach(ele => {
                _arr.push({value: ele.id, text: ele.name})
            })
            state.options[0] = _arr
            const info = await getUserInfo()
            state.card = info.betCardNo
        }
        initData()
        onActivated(() => {
            goTop(state.scrollTopValue)
        })
        function finish(event) {
            // console.log(event)
            state.chooseOptions[state.showOptionIndex] = event.selectedOptions[0]

            state.show = false
            state.list = []
            state.page = 1
            state.chooseIndex = -1
            loadData()
        }
        function getParams () {
            const time = state.chooseOptions[1]
            console.log(time)
            const {start, end} = stardAndEndTime(time.value)
            return {
                gameId: state.chooseOptions[0].value,
                startTime: start,
                endTime	: end,
                betCardNo: state.card,
                page: state.page,
                pageSize: state.pageSize,
            }
        }
        async function loadData() {
            try {
                if(!state.card) {
                    await initData()
                }
                const index = state.tabIndex
                const result = index == 0 ? await lotteryRecard(getParams()) :
                    index == 1 ? await winRecard(getParams()) : await termlyLotteryRecard(getParams())
                const _list = result.ticketList || result.orderList || result.planList
                state.list.push(..._list)
                state.finished = result.count < state.list.length + 1
                state.page++
                // if(index ==2) {
                //     state.list = []
                //     state.finished = true
                // }
                state.loading = false
            } catch(e) {
                console.log(e)
            }
        }
        loadData()
        function changeTab(index) {
            state.tabIndex = index
            state.isWinPage = index == 1
            state.page = 1
            state.list = []
            state.chooseIndex = -1
            loadData()
        }
        function showOptions(index) {
            state.showOptionIndex = index
            state.show = true
        }
        // 查看详情
        function goDtail(value, index) {
            state.scrollTopValue = getTopValue()
            state.chooseIndex = index
            changeLotteryRecardStatus(true)
            if (value.gameId && (value.issueName || isTermly.value) && value.orderId) {
                jumpTo(`/lotteryDetail?gameId=${value.gameId}&issueName=${value.issueName}&orderId=${value.orderId}&termly=${isTermly.value}`)
            }
        }
        return {
            ...toRefs(state),
            finish,
            changeTab,
            showOptions,
            showOption,
            title,
            titles,
            goDtail,
            hasData,
            loadData,
            boxRef,
            tip,
            isTermly
        }
    },
}
</script>
<style lang='scss' scoped>
.recard {
    background: #fff;
    :deep .lottery_item {
        margin-bottom: 10px;
    }
    :deep .all_white {
       overflow-y: 100%;
    }
    :deep .recard_list {
        background: #fff;
    }
    .dark_gray {
        background: #e4e4e4 !important;
    }
    &::after{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: -1;
    }
    &_heads {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px 10px 10px;
        font-weight: 700;
        font-size: $font-big;
        &_item {
            width: 50%;
            text-align: center;
            p {
                display: inline-block;
                padding: 0 18px;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $gray-light;
                }
            }
            &:first-child {
                p {
                    color: $theme-dark;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        right: 18px;
                        height: 2px;
                        background: $theme-dark;
                        bottom: -5px;
                        left: 18px;
                    }
                }
            }
        }
    }
    &_tabs {
        background: $bg-gray;
        margin: $margin-big*2 $margin-big $margin-big $margin-big;
        display: flex;
        font-size: $font-big;
        justify-content: space-between;
        padding: 5px;
        border-radius: $box-radius-small;
        li {
            width: 28%;
            color: $gray-dark;
            text-align: center;
            padding: 5px 0;
        }
        &_active {
            background: #fff;
            color: #000;
            font-weight: 700;
            border-radius: $box-radius-small;
        }
    }
    &_list {
        padding: 0 $margin-big;
    }
}
</style>